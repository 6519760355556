import { Post, PostQuery } from 'common-types'
import { Select, TextInput } from 'components/global/FormInputs'
import { Button } from '@arckit/ui'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import ArticleCard from 'components/news/ArticleCard'
import Card from 'components/news/Card'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'

import SectionTitle from '../components/global/SectionTitle'
import { StaticImage } from 'gatsby-plugin-image'
import { P } from 'components/typography/Paragraph'

const DistrictResultsPage = () => {
	const posts = useStaticQuery<PostQuery>(query)

	const nonFeaturedPosts = posts.allContentfulPost.edges
		.filter((item) => item.node.featuredPost !== true)
		.map((item) => ({
			year: item.node.publishedDate.substring(item.node.publishedDate.length - 4),
			postType: item.node.category,
			postState: item.node.state,
			...item.node
		}))
	const featuredPosts = posts.allContentfulPost.edges.filter((item) => item.node.districtResultFeatured === true)
	const [years] = useState(reduceYears())
	const [states] = useState(reduceStates())
	const [search, setSearch] = useState('')
	const [searchFilter, setSearchFilter] = useState(nonFeaturedPosts)
	const [filter, setFilter] = useState({ year: 'all', postType: 'all', postState: 'all' })
	const [postFilter, setPostFilter] = useState(nonFeaturedPosts)

	const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target
		setFilter((prevState) => ({ ...prevState, [name]: value }))
	}

	useEffect(() => {
		function search(post: Post) {
			// @ts-ignore
			return Object.keys(this).every((key) => post[key] === this[key] || this[key] === 'all')
		}
		const filteredPosts = nonFeaturedPosts.filter(search, filter)
		setPostFilter(filteredPosts)
		// eslint-disable-next-line
	}, [filter])

	useEffect(() => {
		const searchFilterPosts = nonFeaturedPosts.filter(
			(post) => post.title.toLowerCase().includes(search.toLowerCase()) || post.content.raw.toLowerCase().includes(search.toLowerCase())
		)
		setSearchFilter(searchFilterPosts)
		// eslint-disable-next-line
	}, [search])

	function reduceYears() {
		let allYears: string[] = []
		nonFeaturedPosts.forEach((post) => allYears.push(post.publishedDate.substring(post.publishedDate.length - 4)))
		const newYears = [...new Set(allYears)]
		return newYears
	}

	function reduceStates() {
		let allStates: string[] = []
		nonFeaturedPosts.forEach((post) => allStates.sort().push(post.state))
		const newStates = [...new Set(allStates)]
		return newStates
	}

	return (
		<Layout pageName="District Results">
			<Section className="overflow-hidden pt-10 pb-20">
				<div className="absolute top-0 h-full w-full bg-white">
					<div className="rainbow-gradient-light absolute right-0 bottom-0 top-auto h-[250px] w-[250px] rounded-[30%_70%_71%_29%_/_30%_30%_70%_70%] opacity-50 blur-[40px] sm:block md:bottom-auto md:top-8 md:h-[500px] md:w-[500px]"></div>
					<div className="rainbow-gradient-light absolute left-0 right-auto -top-[110px] hidden h-[300px] w-[300px] -translate-x-[50%] translate-y-[20%] rounded-full opacity-50 blur-[40px] sm:block"></div>
				</div>

				<Row center className="relative">
					<Col width="w-full " className="flex items-center justify-center max-w-lg">
						<StaticImage src="../../static/images/district-results/arc-gets-results.png" alt="arc gets results logo" />
					</Col>
				</Row>
				<Row className="relative">
					<Col width="w-full md:w-3/5" className="text-center mx-auto">
						<P className="mb-4">
							Since 1997, ARC has been transforming literacy outcomes in schools and districts nationwide. The results speak for themselves—rises in proficiency,
							dramatic drops in students reading significantly below grade level, and countless stories of schools building strong reading (and writing) cultures.​
						</P>
						<P className="mb-4">​From pandemics to politics, ARC districts get results under any conditions.​</P>
						<P>Explore the impact of ARC’s research-based approach and see how schools like yours are achieving lasting literacy gains. </P>
					</Col>
				</Row>
			</Section>

			<Section margin="mt-20">
				<SectionTitle title="Highlights" />
				<Row>
					{featuredPosts.slice(0, 3).map((post) => {
						return <Card key={post.node.id} data={post.node} />
					})}
				</Row>
			</Section>
			<Section margin="mt-10">
				<SectionTitle title="Recent News" />

				<Row>
					<Col className="mb-6">
						<div className="flex flex-col p-2 md:flex-row md:items-center md:p-6">
							<svg xmlns="http://www.w3.org/2000/svg" className="mr-2 hidden h-5 w-5 text-slate-400 sm:block" viewBox="0 0 20 20" fill="currentColor">
								<path
									fillRule="evenodd"
									d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
									clipRule="evenodd"
								/>
							</svg>
							<div className="my-4 max-w-max md:my-0 ">
								{years && (
									<Select id="year" name="year" value={filter.year} onChange={handleFilterChange}>
										{/* eslint-disable-next-line */}
										<option value="all">All Years</option>
										{years.map((year, index) => {
											return (
												<option key={`${year}-${index}`} value={year}>
													{year}
												</option>
											)
										})}
									</Select>
								)}
							</div>

							<div className="max-w-max md:ml-4">
								{states && (
									<Select id="state" name="postState" value={filter.postState} onChange={handleFilterChange}>
										{/* eslint-disable-next-line */}
										<option value="all" className="pr-4">
											All States
										</option>
										{states.map((state, index) => {
											return (
												<option key={`${state}-${index}`} value={state}>
													{state}
												</option>
											)
										})}
									</Select>
								)}
							</div>

							<div className="my-4 max-w-max border border-solid md:my-0 md:ml-auto">
								<TextInput
									id="search"
									name="search"
									type="text"
									placeholder="Search all posts"
									value={search}
									search
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
									className="max-w-max"
								/>
							</div>
						</div>
					</Col>
					{!search && (
						<Col>
							{filter.postState === 'all' && filter.postType === 'all' && filter.year === 'all' ? (
								<div className="flex w-full flex-auto flex-wrap">
									{nonFeaturedPosts.slice(0, 6).map((post) => {
										return <Card key={post.id} data={post} />
									})}
								</div>
							) : postFilter.length > 0 ? (
								postFilter.map((post) => <ArticleCard key={post.id} data={post} />)
							) : (
								<h3 className="text-center">No articles match this search</h3>
							)}
						</Col>
					)}
					{search && (
						<Col>
							{searchFilter.length > 0 ? (
								searchFilter.map((post) => <ArticleCard key={post.id} data={post} />)
							) : (
								<h3 className="text-center">No articles match this search</h3>
							)}
						</Col>
					)}
				</Row>
				<Row>
					<Col>
						{postFilter.length > 0 && searchFilter.length > 0 ? (
							<div className="mb-10 flex justify-center">
								<Link to="/news/archive">
									<Button size="lg">See More</Button>
								</Link>
							</div>
						) : null}
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

const query = graphql`
	query {
		allContentfulPost(sort: { fields: publishedDate, order: DESC }, filter: { category: { eq: "District Result" } }) {
			edges {
				node {
					title
					snippet
					id
					image {
						file {
							url
						}
						title
					}
					content {
						raw
					}
					category
					state
					districtResultFeatured
					source
					publishedDate(formatString: "MMMM DD, YYYY")
					slug
				}
			}
		}
	}
`

export default DistrictResultsPage
